<template>
  <div class="pay-success">
    <div class="top-title">
      <span class="logo"></span>
      <span class="service" @click="openService()">
        <span class="redDot-class" v-if="isRedDot"></span>
      </span>
    </div>
    <div class="content">
      <div class="success-icon"></div>
      <div class="success-text">{{ $t('userCenter.paySuccessTips') }}</div>
      <div class="confirm-btn" @click="goIndex">{{ $t('public.confirmBtn') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isRedDot:false
    }
  },
  created () {
    window.haveCustomerServiceMessage=(type)=>{
      this.redDot(type)
    }
    window.eaH5PageBack = this.goIndex
    this.$H5Object.changeStatusBarBgColor('#F4F5FC')
    this.$H5Object.isShowBottomTabinter(false)
    this.$H5Object.changeStatusIsDarkFont(true)
  },
  methods: {
    // 客服头像 是否展示红点
    redDot(type){
      console.log('客服红点1');
      this.isRedDot = type
    },
    goIndex() {
      console.log(22);
      this.$H5Object.homeclick()
    },

    openService() {
      this.$H5Object.goCustomServicePage()
    }
  }
}
</script>
<style lang="scss" scoped>
.pay-success{
  padding: 14px 40px;
  background: #ffff;
.top-title{
  display: flex;
  justify-content: space-between;
  .logo{
    width: 168px;
    height: 62px;
    background: url('~@/assets/images/stayPay/pay-success-log-icon.png') no-repeat center center;
    background-size: 100%;
  }
  .service{
    width: 44px;
    height: 44px;
    background: url('~@/assets/images/stayPay/server-icon.png') no-repeat center center;
    background-size: 100%;
    position: relative;
    .redDot-class{
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        background: red;
        border-radius: 50%;
        top: 0;
        right: 0;
      }
  }
}
.success-icon{
    width: 380px;
    height: 244px;
    margin: 0 auto;
    margin-top: 242px;
    margin-bottom: 64px;
    background: url('~@/assets/images/stayPay/pay-success-icon.png') no-repeat center center;
    background-size: 100%;
  }
  .success-text{
    font-size: 36px;
    font-weight: 500;
    color: #1C1D21;
    line-height: 52px;
    text-align: center;
    margin-bottom: 32px;
  }
  .confirm-btn{
    height: 128px;
    line-height: 128px;
    background: #005FE9;
    border-radius: 32px;
    font-size: 32px;
    font-weight: 500;
    color: #FFFFFF;
    text-align: center;
  }
}

</style>
